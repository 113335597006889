import { signIn as nextSignIn, signOut as nextSignOut } from 'next-auth/react';

import { paths } from '@helpers/path';

const providerName = 'oauth2';

export async function signIn(callbackUrl?: string) {
    return nextSignIn(providerName, { redirectTo: callbackUrl });
}

export async function signOut(callbackUrl: string = paths.phoneWall) {
    return nextSignOut({ redirectTo: callbackUrl });
}
