'use client';

import { SessionProvider, SessionProviderProps } from 'next-auth/react';
import React, { useEffect } from 'react';

import { TrilogyProviderStyled } from '@bytel/trilogy-react-ts/lib/context/providerStyled';

import { signIn } from '@helpers/authentication';

import { isPast } from 'date-fns';

export function Provider({ children }: { children: React.ReactNode }) {
    return <TrilogyProviderStyled>{children}</TrilogyProviderStyled>;
}

export function NextAuthProvider({ children, session }: SessionProviderProps) {
    useEffect(() => {
        if (session?.user?.exp && isPast(session.user.exp * 1000)) {
            document.cookie = 'next-session-token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
            signIn();
        }
    }, [session]);

    return (
        <SessionProvider session={session} basePath='/webapi/auth'>
            {children}
        </SessionProvider>
    );
}
